import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne:
      'The last time inflation reached over 9 percent was November 1981. So, what’s looming over the next phase of economic growth? The answer to that question is the risk of inflation, which has returned to the front page of every newspaper around the world. A 9% annual inflation rate is certainly not what CFOs were planning for when drafting their annual operating plans a year ago. And given the inflationary trend continuing across the world’s economy, companies are in immediate need to hedge against the pace of inflation.',
    ParaTwo:
      'Making a business more recession-proof requires advanced planning and switching to digital solutions at the right time with the right partner. Raindrop spend management solution is crucial for all businesses, be it enterprise level, or SMBs to focus on revenue preservation, cash flow, capital management, and investing in strategic demand generation. With automation and AI, Raindrop provides immediate access to advanced spend intelligence across different dimensions of spend, inclusive of commodity acquisition patterns, predictable opportunities, and risk involved with external supplier spend, visibility into commodity consumption, recover costs from under-utilized commodities, and contain costs before over-consumption. Know who your money is going to, and how much you’re spending with each external entity. Raindrop’s powerful business intelligence enables your company to build predictive sourcing strategies across each functional area which are the building blocks for effective spend management.',
    ImagePathOne:
      'https://storage.googleapis.com/raindroppublic/website_data/A95B913B-F35D-4016-B188-0E2CD5E06824.jpeg',
    ParaThirteenLeft: '',
    ParaAnchor: 'US Economy Forecast (Source – Deloitte)',
    ParaThirteenRight: '',
    AnchorShift:
      'https://www2.deloitte.com/us/en/insights/economy/us-economic-forecast/united-states-outlook-analysis/_jcr_content/root/responsivegrid_380572564/advanced_image.coreimg.95.800.jpeg/1655373867927/us-175517-figure1.jpeg',
    ParaThree:
      'Raindrop recognizes the critical requirement of effective spend management solution the current market conditions, especially as it relates to companies who need to manage spend effectively and in a predictive manner but may not have the technology solutions available to support their needs. Here are some of the reasons you need to choose the right spend management platform to accelerate business growth during the economic downturn.',
    HeadingOnePointOne: 'Supply Chain Visibility',
    ParaFour:
      'As supply chain management has been growing increasingly complex, CFOs and Sourcing leaders have taken on a higher degree of criticality by adopting technology-driven solutions to maximize fully-integrated spend transparency. Enterprise Spend Management technologies such as Raindrop deliver increased efficiencies, reduced cycle times, enhanced internal and external collaboration, materials consumption visibility, and higher transparency across all contractual obligations. Raindrop’s cloud-based intelligent, scalable, and secured solution provides real-time visibility in the supply chain for proactive decision-making to manage products, people, and processes in a better and digitized way.',
    HeadingOnePointTwo: 'Strategic Capital Allocation',
    ParaFive:
      'The need for rethinking capital allocation has intensified due to the rising inflationary trend. A majority of business leaders believe that they need to improve their capital allocation strategy, but accessing data is the main challenge. Raindrop’s Spend Management system allows businesses to analyze and plan their corporate spending in an efficient and intelligent way. Raindrop allows Finance leaders to have real-time visibility into enterprise spending and manage fiscal commitments proactively. Raindrop provides 360-degree supplier insights and transparency, who in your company are buying things, from which suppliers, and track where your contractual obligations are going. Raindrop’s powerful business intelligence enables CFOs to plan and manage commodities, spend categories, create supplier consolidation strategies, and build predictive sourcing strategies across each functional area. Translate your capital allocation strategy to action and measure adherence to the published plan.',
    HeadingOnePointThree: 'Sound Financial Planning',
    ParaSix:
      'Sound financial annual planning creates the baseline from which every company’s external supplier spend is driven, including existing run rate spend, to net-new capital investments. Raindrop’s Planning Module provides complete budget planning capabilities to create multiple financial scenario models over time. Let’s say the cost of cotton has risen 10% this year and you manufacture cotton made clothes, how do you react to the market and plan accordingly becomes critical to the success of your company. However, when a market price is known, and can be planned over the course a fiscal year, even when your company must buy more quantities of a product or service, then Raindrop’s Planning Module can immediately show you how each decision impacts your financial plan, displayed in real-time.',
    HeadingOnePointFour: 'Cost Containment',
    ParaSeven:
      'With Raindrop’s guided machine learning, get savings recommendations on your upcoming purchase requirements. Raindrop’s Machine Learning technology helps to identify your commodities historic and current discounts and buying patterns and provides precise recommendations for cost savings and cost containment rates. Raindrop proudly levels the playing field for the buying side.',
    HeadingOnePointFive: 'More Spend Under Management',
    ParaEight:
      'Attain 95% visibility of supplier and commodity spend. With the power of Raindrop, you can achieve greater data visibility and identify cost savings opportunities, including areas for contract consolidation & preventing unnecessary purchases.',
    ParaNine:
      'Precise data tracking and contract renewal notifications help in preparation for renewal negotiations, avoiding automatic renewals, late payments, while securing the most beneficial terms for your company.',
  },
]
