import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
// import { ReUseHTwotag } from './ReUseHTwoTag'
import HowtoCombatInflationduringtheRecessionPendingData from '../Data/HowtoCombatInflationduringtheRecessionPendingData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
// import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
// import { ReUsePtagwithThreeAnchorTag } from './ReUsePtagwithThreeAnchorTag'

const AppHowtoCombatInflationduringtheRecessionRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustryContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RetailIndustryHeadingEmail {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .RetailIndustryParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 24px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .RetailIndustryHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }

  .CFOsPriorityParaStyle {
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CFOsPriorityachorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 120px;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  .RFPProcessLeadParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
`

export const AppHowtoCombatInflationduringtheRecessionRest = () => {
  return (
    <AppHowtoCombatInflationduringtheRecessionRestWapper>
      <div>
        {HowtoCombatInflationduringtheRecessionPendingData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="RetailIndustryContentSectionEmail">
                <ReUsePtag para={data.ParaOne} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag para={data.ParaTwo} paraStyle="ParaStyleMultiple" />
                <img src={data.ImagePathOne} className="BlogsimageOne" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaThirteenLeft}
                  anchorPara={data.ParaAnchor}
                  ParaRight={data.ParaThirteenRight}
                  href={data.AnchorShift}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="CFOsPriorityachorPara"
                  paraStyle="CFOsPriorityParaStyle"
                />
                <ReUsePtag para={data.ParaThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingOnePointOne}
                  paraStyle="RFPProcessLeadParaSubPointStyle"
                  IconStyle="iconColor"
                />
                <ReUsePtag para={data.ParaFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingOnePointTwo}
                  paraStyle="RFPProcessLeadParaSubPointStyle"
                  IconStyle="iconColor"
                />
                <ReUsePtag para={data.ParaFive} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingOnePointThree}
                  paraStyle="RFPProcessLeadParaSubPointStyle"
                  IconStyle="iconColor"
                />
                <ReUsePtag para={data.ParaSix} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingOnePointFour}
                  paraStyle="RFPProcessLeadParaSubPointStyle"
                  IconStyle="iconColor"
                />
                <ReUsePtag para={data.ParaSeven} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingOnePointFive}
                  paraStyle="RFPProcessLeadParaSubPointStyle"
                  IconStyle="iconColor"
                />
                <ReUsePtag para={data.ParaEight} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUsePtag para={data.ParaNine} paraStyle="ParaStyleMultiple" />
              </div>
            </div>
          )
        })}
      </div>
    </AppHowtoCombatInflationduringtheRecessionRestWapper>
  )
}
